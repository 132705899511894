import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import slugify from "slugify";

import { baseEnvironmentConfig } from "@/api/constants";
import { fetchUserInfo } from "@/api/user";
import DynamicRenderer from "@/components/DynamicRenderer";
import HomepageHero from "@/components/HomepageHero";
import Layout from "@/components/Layout";
import MailingListForm from "@/components/MailingListForm";
import ModuleEditorialFullWidth from "@/components/editorial/fullWidth";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { CONTENT_TYPES } from "@/constants";
import { UserContext } from "@/context/User";
import useAcdlGlobalPageTracking from "@/hooks/useAcdlGlobalTracking";
import { getCmsEntries } from "@/server/lib/contentful";
import { pushToHash } from "@/utils/helpers/extract-section-id";

const styles = { "margin-top": "104px" };
const moduleEditorialGrid2Column = "moduleEditorialGrid2Column";
const moduleBreaker = "moduleBreaker";

const LandingPage = ({
  activationCode,
  adobeAnalytics,
  articleQuery,
  form,
  hero,
  informative,
  metaData,
  moduleSpacing,
  navigation,
  notificationBanner,
  token,
  uiModules = [],
}) => {
  const { initialized, updateUserSession } = useContext(UserContext);
  const router = useRouter();

  const { asPath } = router;

  const scrollFunc = (window, asPath) => {
    if (window) {
      if (window.location.hash) {
        pushToHash(window.location.hash);
      }
    } else {
      pushToHash(asPath);
    }
  };

  useEffect(() => {
    const hydrateUserInfo = async () => {
      const userInfo = await fetchUserInfo(token);

      if (userInfo) {
        updateUserSession(userInfo.userSession);
      }
    };

    if (token && initialized) {
      hydrateUserInfo();
    }
  }, [initialized, token, updateUserSession]);

  useEffect(() => {
    scrollFunc(window, asPath);

    if (window) {
      window.addEventListener("hashchange", () => {
        scrollFunc(window, asPath);
      });
    }

    if (window) {
      return () => {
        window.removeEventListener("hashchange", scrollFunc);
      };
    }
  }, [asPath]);

  // ACDL Global Tracking Custom Hook
  useAcdlGlobalPageTracking(
    ACDL_GLOBAL_TRACKING.nonProgrammaticUrlRoute,
    metaData?.fields?.title?.toLowerCase() || "",
    undefined,
    adobeAnalytics
  );

  return (
    <Layout
      hideFooter={!!token}
      metaData={metaData?.fields}
      moduleSpacing={moduleSpacing}
      modules={uiModules}
      navigation={token ? null : navigation}
      notificationBanner={notificationBanner}
      page={baseEnvironmentConfig.pageNames.landing}
    >
      {hero && <HomepageHero hero={hero} />}

      {informative && (
        <ModuleEditorialFullWidth data={{ ...informative.fields }} />
      )}

      {uiModules?.map((module, index) => {
        const fileName = module?.sys?.contentType?.sys?.id;

        if (
          index > 0 &&
          fileName === moduleEditorialGrid2Column &&
          uiModules[index - 1].sys.contentType.sys.id === moduleBreaker
        ) {
          return (
            <DynamicRenderer
              articleQuery={articleQuery}
              data={module?.fields}
              fileName={fileName}
              id={module?.sys?.id}
              index={index}
              key={`${module?.sys?.id}-${index + 1}`}
              propStyles={styles}
            />
          );
        }

        return (
          <DynamicRenderer
            articleQuery={articleQuery}
            data={module?.fields}
            fileName={fileName}
            id={module?.sys?.id}
            index={index}
            key={`${module?.sys?.id}-${index + 1}`}
          />
        );
      })}

      {form && (
        <MailingListForm {...form.fields} activationCode={activationCode} />
      )}
    </Layout>
  );
};

LandingPage.propTypes = {
  activationCode: PropTypes.string,
  adobeAnalytics: PropTypes.object,
  articleQuery: PropTypes.string,
  form: PropTypes.object,
  hero: PropTypes.object,
  informative: PropTypes.object,
  metaData: PropTypes.object,
  moduleSpacing: PropTypes.string,
  navigation: PropTypes.object,
  notificationBanner: PropTypes.object,
  token: PropTypes.string,
  uiModules: PropTypes.array,
};

export const getServerSideProps = async ({ query, req, resolvedUrl }) => {
  const { slug, articleQuery } = query;

  /**
   * This checks to see if the slug is being used i.e /landing/:slug
   * else it will pass along the asPath for the following /shop and /ebyequinox
   */

  const filters = {
    "fields.slug": !slug
      ? slugify(
          resolvedUrl.split("?")[0].split("/")[1] &&
            resolvedUrl.split("?")[0].split("/")[1].toLowerCase()
        )
      : slugify(slug.toLowerCase()),
  };

  const landingPageData = await getCmsEntries({
    contentType: CONTENT_TYPES.LANDING,
    filters,
    include: 4,
  });

  if (!landingPageData?.fields) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      ...landingPageData?.fields,
      articleQuery: articleQuery || null,
      token: req?.headers?.authorization || req?.query?.token || null,
    },
  };
};

export default LandingPage;
